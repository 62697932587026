'use client';

import { AuthInfoResponseBody } from 'bff';
import { useEffect } from 'react';

export const PushUserGTM = ({ user }: { user: AuthInfoResponseBody }) => {
  useEffect(() => {
    (window as any).dataLayer?.push({
      id: user.id,
      email: user.email,
      name: `${user.first_name} ${user.last_name}`,
      company: user.company?.name,
    });
  }, []);

  return null;
};
